import styles from '../styles.module.css'
import React from 'react'
import ElementRenderer from './ElementRenderer'

const ElementsViewer = ({elements,getData,doNotAnimateOut}) => {
  return <div className={styles.reactElementContainer}>
    {
      elements.map((el,i)=><ElementRenderer element={el} key={i} getData={getData} doNotAnimateOut={doNotAnimateOut}/>)
    }
  </div>
}

export default ElementsViewer
