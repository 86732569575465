import React from 'react'

const TextElement = ({element}) => {
  const { data } = element
  const {text, fontSize, color, textAlign, fontFamily, textDecoration, fontStyle, fontWeight} = data
  const textStyle = {fontSize, color, textAlign, fontFamily, textDecoration, fontStyle, fontWeight}

  return <span style={{...textStyle, width: '100%', height: '100%', display:'inline-block', whiteSpace:'pre-line' }}>
    {text}
  </span>
}

export default TextElement
