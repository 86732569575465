export const SETTINGS = {
  DEBUG_MODE: false
}

import TextElement from "./components/elements/TextElement";
import ImageElement from "./components/elements/ImageElement";

export const ELEMENT_COMPONENTS = {
  TEXT: TextElement,
  IMAGE: ImageElement
}
