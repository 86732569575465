import React,{ReactFragment,useState,useEffect} from "react";

const ImageElement = ({ element,getData }) => {
  const {data,name} = element
  const {uuid, from} = data

  const [imageData, setImageData] = useState()

  const setData = (data) => {
    setImageData(data)
  }

  useEffect(()=>{
    getData(uuid, setData, from)
  },[])

  return <div> {imageData&&<img src={imageData} width={'100%'} height={'100%'} alt={name} />} </div>
}
export default ImageElement
