import styles from '../styles.module.css'
import React, { useEffect, useState } from 'react'

const BackgroundWrapper = ({background, children, zIndex=0,key,getData, storyDimensions}) => {
  const [imageData, setImageData] = useState()

  function getBase64Image(src, callback, outputFormat) {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      let dataURL;
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };

    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      img.src = src;
    }
  }

  const setData = (data) => {
    if (data.includes('file://')) {
      getBase64Image(data,setImageData,'image/png')
    } else {
      setImageData(data)
    }
  }

  const backgroundStyle = !!background?{
    backgroundColor: background.color,
    backgroundImage: background.uuid?`url('${imageData}')`:``,
    backgroundSize: background.size,
    backgroundRepeat: background.repeat,
    backgroundPosition:background.position,
    zIndex,
    height: storyDimensions.height,
    width: storyDimensions.width
  }:{}

  useEffect(()=>{
    if (background) {
      const {uuid, from} = background
      if (uuid) getData(uuid, setData, from)
    }
  },[])

  return <div className={styles.reactStoriesSlide} style={{...backgroundStyle}} key={key}>
    {children}
  </div>
}

export default BackgroundWrapper
