export const simple_story = {
  "width": 1440,
  "height": 2560,
  "slides": [
    {
      "id": "KM2U8S7DYa",
      "name": "Slide 1",
      "duration": 10000,
      "elements": [
        {
          "x": 950.7778002043833,
          "y": 79.39999389648439,
          "id": "ORENFifCRz",
          "data": {
            "from": "stories",
            "uuid": "042f8a1a-9579-43b4-b692-cd5458580430",
            "resized": false,
            "lockAspectRatio": true,
            "dimensionsAreSet": true
          },
          "name": "",
          "type": "IMAGE",
          "width": 301,
          "height": 91,
          "zIndex": 1,
          "rotation": 0,
          "animation": {
            "durationEnter": 1000,
            "durationLeave": 1000,
            "enterAnimation": "fadeInRight",
            "leaveAnimation": "fadeOut",
            "enterAnimationDelay": 0,
            "leaveAnimationDelay": 9000,
            "editorElementDuration": 1
          }
        },
        {
          "x": 58.66500309414062,
          "y": 333.2570265374835,
          "id": "VHsSA0GCHZ",
          "data": {
            "text": "200 χρόνια Μικροί - μεγάλοι Ήρωες",
            "color": "#f3dbbc",
            "fontSize": 60,
            "textAlign": "left",
            "fontFamily": "GFS Didot",
            "fontWeight": 300
          },
          "name": "",
          "type": "TEXT",
          "width": 1117,
          "height": 106,
          "zIndex": 1,
          "rotation": 0,
          "animation": {
            "durationEnter": 1000,
            "durationLeave": 1000,
            "enterAnimation": "fadeIn",
            "leaveAnimation": "fadeOut",
            "enterAnimationDelay": 1250,
            "leaveAnimationDelay": 7500,
            "editorElementDuration": 1
          }
        },
        {
          "x": 73.55102958903169,
          "y": 464.160001408622,
          "id": "xeYsW306FW",
          "data": {
            "from": "stories",
            "uuid": "d935ebfd-c876-4e79-8174-076a55508711",
            "resized": false,
            "lockAspectRatio": true,
            "dimensionsAreSet": true
          },
          "name": "",
          "type": "IMAGE",
          "width": 882,
          "height": 1161,
          "zIndex": 1,
          "rotation": 0,
          "animation": {
            "durationEnter": 1000,
            "durationLeave": 1000,
            "enterAnimation": "fadeIn",
            "leaveAnimation": "fadeOut",
            "enterAnimationDelay": 1250,
            "leaveAnimationDelay": 7250,
            "editorElementDuration": 1
          }
        },
        {
          "x": 82.96604197870846,
          "y": 1659.2064583549954,
          "id": "pL3lJanK1C",
          "data": {
            "text": "Ρήγας Βελεστινλής\n(Φεραίος)",
            "color": "#f3dbbc",
            "fontSize": 60,
            "textAlign": "left",
            "fontFamily": "GFS Didot",
            "fontWeight": 300
          },
          "name": "",
          "type": "TEXT",
          "width": 573,
          "height": 211,
          "zIndex": 1,
          "rotation": 0,
          "animation": {
            "durationEnter": 1000,
            "durationLeave": 1000,
            "enterAnimation": "fadeInUp",
            "leaveAnimation": "fadeOut",
            "enterAnimationDelay": 1250,
            "leaveAnimationDelay": 7250,
            "editorElementDuration": 1
          }
        },
        {
          "x": 92.83671368596293,
          "y": 2221.090261421713,
          "id": "bcAp5f5wnp",
          "data": {
            "text": "Βελεστίνο, 1757 - Βελιγράδι, 1798",
            "color": "#f3dbbc",
            "fontSize": 40,
            "textAlign": "left",
            "fontFamily": "GFS Didot",
            "fontWeight": 300
          },
          "name": "",
          "type": "TEXT",
          "width": 733,
          "height": 111,
          "zIndex": 1,
          "rotation": 0,
          "animation": {
            "durationEnter": 1000,
            "durationLeave": 1000,
            "enterAnimation": "fadeInUp",
            "leaveAnimation": "fadeOut",
            "enterAnimationDelay": 1250,
            "leaveAnimationDelay": 7250,
            "editorElementDuration": 1
          }
        },
        {
          "x": 86.06079216702791,
          "y": 1893.159973112287,
          "id": "mFhMb694pF",
          "data": {
            "text": "Λόγιος τους Έθνους, διαφωτιστής, επαναστάτης, προδρομική μορφή της Ελληνικής Επανάστασης του 1821",
            "color": "#f3dbbc",
            "fontSize": 45,
            "textAlign": "left",
            "fontFamily": "GFS Didot",
            "fontWeight": 300
          },
          "name": "",
          "type": "TEXT",
          "width": 761,
          "height": 217,
          "zIndex": 1,
          "rotation": 0,
          "animation": {
            "durationEnter": 1000,
            "durationLeave": 1000,
            "enterAnimation": "fadeInUp",
            "leaveAnimation": "fadeOut",
            "enterAnimationDelay": 2000,
            "leaveAnimationDelay": 7500,
            "editorElementDuration": 1
          }
        }
      ],
      "animation": {
        "durationEnter": 1000,
        "durationLeave": 1000,
        "enterAnimation": "fadeIn",
        "leaveAnimation": "fadeOut",
        "editorSlideDuration": 5,
        "enterAnimationDelay": 0,
        "leaveAnimationDelay": 9000
      },
      "background": {
        "from": "stories",
        "size": "cover",
        "uuid": "74017322-2da1-4f19-8126-bcce355e9406",
        "color": "#fff",
        "position": "center center"
      }
    },
    {
      "id": "imTuc3XfRQ",
      "name": "Slide 2",
      "duration": 3000,
      "background": {
        "color": "#fff",
        "position": "center center",
        "uuid": "313a86e6-07fc-4892-bb7f-d357f461b37e",
        "size": "cover",
        "from": "stories"
      },
      "animation": {
        "enterAnimation": null,
        "leaveAnimation": null,
        "editorSlideDuration": 5,
        "enterAnimationDelay": 10000,
        "leaveAnimationDelay": 14000,
        "durationEnter": 1000,
        "durationLeave": 1000
      },
      "elements": [
        {
          "id": "wUW8b8Hay0",
          "type": "IMAGE",
          "name": "",
          "x": 275.4402323863328,
          "y": 146.2478336221837,
          "zIndex": 1,
          "height": 1140,
          "width": 866,
          "rotation": 0,
          "animation": {
            "editorElementDuration": 1,
            "enterAnimation": null,
            "leaveAnimation": null,
            "enterAnimationDelay": 1000,
            "leaveAnimationDelay": 1000,
            "durationEnter": 1000,
            "durationLeave": 1000
          },
          "data": {
            "uuid": "d935ebfd-c876-4e79-8174-076a55508711",
            "from": "stories",
            "lockAspectRatio": true,
            "dimensionsAreSet": true
          }
        },
        {
          "id": "oFKF6fvjuA",
          "type": "TEXT",
          "name": "",
          "x": 412.6169844020798,
          "y": 1326.5857580439472,
          "zIndex": 1,
          "height": 152,
          "width": 639,
          "rotation": 0,
          "animation": {
            "editorElementDuration": 1,
            "enterAnimation": null,
            "leaveAnimation": null,
            "enterAnimationDelay": 1000,
            "leaveAnimationDelay": 1000,
            "durationEnter": 1000,
            "durationLeave": 1000
          },
          "data": {
            "text": "This is me",
            "color": "#ffffff",
            "fontSize": 40,
            "textAlign": "center",
            "fontWeight": 300
          }
        }
      ]
    }
  ]
}

// async function getData(url = '', data = {}) {
//
//   const storyCache = await caches.open('story-cache');
//
//   const options = {
//     method: 'GET',
//     mode: 'cors',
//     cache: 'force-cache',
//     credentials: 'same-origin',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTYzMTUyNTIyNiwiaWF0IjoxNjE1OTczMjI2fQ.qrluOXasclM4SOnU081opVIqmhoDMB5B3QpdDa10bmWT5_y9fY71YWULgSH9LM_iZTs1KF-59qwPBVOFcrpOAQ',
//       'X-TenantID': 'test',
//       'Cache-Control': 'max-age=50000'
//     },
//     redirect: 'follow',
//     referrerPolicy: 'no-referrer'
//   }
//
//   const getRequest = new Request(url,options)
//   const cacheResponse = await storyCache.match(getRequest,options)
//
//   if (!cacheResponse) {
//     const response = await storyCache.add(getRequest)
//     return response.blob()
//   } else {
//     return cacheResponse.blob()
//   }
// }
//
//
// export const getDataFromCache = (uuid,setData) => {
//     getData(`https://store-api-dev.repox.io/assets/${uuid}/download`).then((data)=>{
//       setData(URL.createObjectURL(data))
//     })
// }

async function getData(url = '', data = {}) {

  const storyCache = await caches.open('story-cache');

  const options = {
    method: 'GET',
    mode: 'cors',
    cache: 'force-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTYzMTUyNTIyNiwiaWF0IjoxNjE1OTczMjI2fQ.qrluOXasclM4SOnU081opVIqmhoDMB5B3QpdDa10bmWT5_y9fY71YWULgSH9LM_iZTs1KF-59qwPBVOFcrpOAQ',
      'X-TenantID': 'test',
      'Cache-Control': 'max-age=50000'
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer'
  }

  const getRequest = new Request(url,options)
  const cacheResponse = await storyCache.match(getRequest,options)

  if (!cacheResponse) {
    await storyCache.add(getRequest)
    const cacheResponse = await storyCache.match(getRequest,options)
    return cacheResponse.blob()
  } else {
    return cacheResponse.blob()
  }
}

const API_URL = process.env.REACT_APP_REPOX_API_URL||`https://store-api-dev.repox.io`

export const getDataFromCache = (asset_uuid,setData,from) => {
  const URI = from==='store'?`${API_URL}/datastreams/${asset_uuid}`:`${API_URL}/assets/${asset_uuid}/download`
  getData(URI).then((data)=>{
    setData(URL.createObjectURL(data))
  })
}
