import styles from '../styles.module.css'
import React from 'react'
import { ELEMENT_COMPONENTS } from '../consts'
import {Animated} from 'react-animated-css'
import {useRef, useEffect, useState} from 'react'

const ElementRenderer = (props) => {
  const {id, width, height, x, y, rotation, type, animation} = props.element
  const {enterAnimation, leaveAnimation, enterAnimationDelay, leaveAnimationDelay, durationEnter, durationLeave} = animation
  const animationProps = {
    animationIn : enterAnimation,
    animationOut: props.doNotAnimateOut?null:leaveAnimation,
    animationInDelay : enterAnimationDelay,
    animationOutDelay: 0,
    animationInDuration : durationEnter,
    animationOutDuration: durationLeave
  }

  const animationOutTimeout = useRef()

  const [visible, setVisible] = useState(true)

  useEffect(()=> {
    if (leaveAnimationDelay&&!props.doNotAnimateOut) {
      animationOutTimeout.current = setTimeout(() => {
        setVisible(false)
      }, (leaveAnimationDelay + durationLeave))
    }
    return () => {
      clearTimeout(animationOutTimeout)
    }
  },[])

  return <div key={id} className={styles.reactElementContainer} style={{width: width, height: height, left:x, top: y, transform: `rotate(${rotation}deg)`, padding: '10px'}}>
    <Animated {...animationProps} isVisible={visible}> { React.createElement(ELEMENT_COMPONENTS[type],{...props})} </Animated>
  </div>

}

export default ElementRenderer
