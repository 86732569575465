import React,{ useState, useEffect, useRef } from 'react'
import ElementsViewer from './ElementsViewer'
import { Animated } from 'react-animated-css'
import BackgroundWrapper from './BackgroundWrapper'

const SlideViewer = ({slides, slide, key, storyBackground, getData, storyDimensions}) => {

  const {enterAnimation, leaveAnimation, enterAnimationDelay, durationEnter, durationLeave} = slides[slide].animation

  const slideObj = slides[slide]

  useEffect(()=>{
    setVisible(true)
  },[])

  const animationProps = {
    animationIn : enterAnimation,
    animationOut: slides.length===1?null:leaveAnimation,
    animationInDuration : durationEnter,
    animationOutDuration: durationLeave,
    animationOutDelay: 0,
    animationInDelay: 0
  }

  const animationOutTimeout = useRef()

  const [visible, setVisible] = useState(true)

  useEffect(()=>{
    if (slides.length!==1) {
      animationOutTimeout.current = setTimeout(()=>{
        setVisible(false)
      },(slides[slide].duration-durationLeave))
    }

    return ()=>{
      clearTimeout(animationOutTimeout)
    }
  },[])

  return <React.Fragment>
    <Animated {...animationProps} isVisible={visible} key={key}>
      <BackgroundWrapper storyDimensions={storyDimensions} background={slideObj.background?slideObj.background:storyBackground} key={slideObj.id} getData={getData}>
        <ElementsViewer elements={slideObj.elements} getData={getData} doNotAnimateOut={slides.length===1} />
      </BackgroundWrapper>
    </Animated>
  </React.Fragment>
}

export default SlideViewer
